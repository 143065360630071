import { createContext, useState, useEffect } from 'react';

const defaultUserData = {
	linkMode: "current tab",
	userSteamID: "",
};


// This context handles all data stored in localStorage
const UserDataContext = createContext({});
const UserDataProvider = ({ children }) => {
	const storedUserData = localStorage.getItem("userData");
	const [userData, setUserData] = useState(() => {
		return storedUserData ? JSON.parse(storedUserData) : defaultUserData;
	});

	console.log("User data loaded: " + JSON.stringify(userData));

	useEffect(() => {
		console.log("User data updated: " + JSON.stringify(userData));
		localStorage.setItem("userData", JSON.stringify(userData));
	}, [userData]);
	return (
		<UserDataContext.Provider value={{ userData, setUserData }}>
			{children}
		</UserDataContext.Provider>
	);
};

export { UserDataContext, UserDataProvider };
