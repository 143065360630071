import keyIcon from '../assets/keyicon.png';
import { UserDataContext } from './UserDataContext';
import { useContext } from 'react';


export default function KeyPriceDisplay({ keyRate }) {

	const linkMode = useContext(UserDataContext).userData.linkMode;

	return (
		<a href='https://backpack.tf/stats/Unique/Mann%20Co.%20Supply%20Crate%20Key/Tradable/Craftable'
			id="keyPriceLinkArea"
			target={linkMode === "new tab" ? "_blank" : null}
			rel="noreferrer nofollow">
			<div id="keyPriceDisplay">
				<img src={keyIcon} alt="Key Icon" /> ={keyRate} ref
			</div>
		</a>
	)
}
