import React, { useState } from 'react';
import ItemSearchBar from "./ItemSearchBar.js";
import SavedItems from "./SavedItems.js";
import bookmarkIcon from "../assets/bookmarkicon.png";

export default function ItemSearch(props) {
	const itemData = props.itemData;
	const [savedItemsVisible, setSavedItemsVisible] = useState(false);


	function showSavedItems () {
		setSavedItemsVisible(true);
	}

	function hideSavedItems () {
		setSavedItemsVisible(false);
			}
	function toggleSavedItems () {
		if (savedItemsVisible) {
			hideSavedItems();
		} else {
			showSavedItems();
		}
			
	}
	return (
	<div id="itemSearch">
		<SavedItems savedItemsVisible={savedItemsVisible}/>

		<div id="itemSearchBarContainer">
			<button id="savedItemsToggleButton" onClick={toggleSavedItems}>
				<img src={bookmarkIcon} alt="Saved items icon" />
			</button>
			<ItemSearchBar itemData={itemData} />
		</div>
	</div>
		);
}
