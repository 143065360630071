import logo from './assets/tftoolslogo.png';
import CurrencyCalculator from "./components/CurrencyCalc";
import ItemSearch from "./components/ItemSearch";
import ShortcutTab from './components/ShortcutTab';
import LinkModeButton from './components/LinkModeButton';
import SteamIDPopup from "./components/SteamIDPopup";
import { UserDataProvider } from "./components/UserDataContext";
import { useEffect, useState } from 'react';

function App() {

	const [steamIDPopupIsOpen, setSteamIDPopupIsOpen] = useState(false);



	// Fetches data from items.json

	const [itemData, setItemData] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			console.log("Item data fetch triggered")
			try {
				const response = await fetch('/data/items.json');
				// console.log(response);
				if (!response.ok) {
					// Handle non-successful response (e.g., 404 Not Found)
					throw new Error(`Failed to fetch item data: ${response.statusText}`);
				}
				const data = await response.json();
				setItemData(data);
				// console.log('Data:', data);
			} catch (error) {
				// Handle fetch or parsing errors
				console.error('Error fetching item data:', error);
			}
		};

		fetchData();
	}, []);


	// Make sure to change this if the "unique" part is removed!
	// Keys are very often priced with a 1 scrap range, so to simplify
	// calculations, only get the low value

	// Done in two lines to avoid type error when itemData is still an empty object
	const keyData = itemData["Mann Co. Supply Crate Key"]
	const keyValue = keyData ? parseFloat(itemData["Mann Co. Supply Crate Key"]["value"].split("-")[0]) : null;


	// App was supposed to fade in on page load, but "load" and "DOMContentLoaded"
	// events caused compatibility errors in chromium. Oh well :(


	// All components are wrapped in UserDataContext.Provider to allow
	// easy access of locally stored data. Can be moved into each component
	// if necessary

	return (
		<div id="App" className={`AppVisible`}>
			<UserDataProvider>
				<PageHeader />
				<LinkModeButton />
				<ShortcutTab setSteamIDPopupIsOpen={setSteamIDPopupIsOpen} />
				<SteamIDPopup isOpen={steamIDPopupIsOpen} setIsOpen={setSteamIDPopupIsOpen} />
				<ItemSearch itemData={itemData}/>
				<CurrencyCalculator keyValue={keyValue} />
				<PageFooter />
			</UserDataProvider>
		</div>
	);
}


function PageHeader() {
	return (
		<div id="pageHeader">
			<img src={logo} alt="TFtools logo" width={200} height={112} />
			<SplashText />
		</div>
	);
}




function SplashText() {

	const [splashText, setSplashText] = useState("");

	function getRandomSplashText() {
		const splashTexts = [
			"\"Simply the best!\"",
			"Trader's best friend",
			"A key site for refined tastes",
			"Part of a complete breakfast",
			"For all your trading needs",
			"\"Oh no, how many keys is 377 refi-\" Shh. We've got this.",
			"For all your intensive purpoises",
			"As seen on TV!",
			"A personal favourite of pedro.gonsalez2013",
			"Bookmark us to get here quicker!",
			"Engie approved",
			"Used by NASA!",
			"Fastest item search in the west",
			"Because you deserve the best",
			"hat",
			"Consider donating if you like this site!"
		];

		let randText = splashTexts[Math.floor(Math.random() * splashTexts.length)];
		return randText;
	}

	// Update only on site load, not when parent state changes
	useEffect(() => {
		setSplashText(getRandomSplashText());
	}, []);

	return (
		<div id="splashText">
			<i>{splashText}</i>
		</div>
	);
}



function PageFooter() {

	return (
		<div id="pageFooter">
			Developed by Johann AKA Quesamo <br />
			Copyright 2023 - 2024 <br />
			Powered by Steam and Backpack.tf <br /> <br />

			<a href="https://discord.gg/WwPpUFztdr">Got something to say? Join the Discord server!</a>
		</div>
	);
};







export default App;
