import React, {useContext} from 'react';
import {UserDataContext} from './UserDataContext';
import ItemDisplay from './ItemDisplay';

export default function SavedItems({ savedItemsVisible }) {
	const {userData } = useContext(UserDataContext);
	// Fall back to empty array if no items are saved
	const savedItems = userData?.savedItems || [];

	console.log(savedItemsVisible);



	return (
		<div id="savedItems">
			{/*Map all saved items to ItemDisplay components*/}
			<div id="savedItemsList" style={{display: savedItemsVisible ? "grid" : "none"}}>
			{
				savedItems.length > 0 ? (
					savedItems.map((item, index) => (
						<ItemDisplay key={index} itemObj={item} buttons={["removeSaved"]} />
					))
				) : (
							<p id="noSavedItemsLabel"> No items saved </p>
				)
			}

			</div>

		</div>
	);
}
