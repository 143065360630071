import { useContext } from "react";
import { UserDataContext } from "./UserDataContext.js";

let qualityColors = {
	"0": "#B2B2B2", // normal
	"1": "#4D7455", // genuine
	"3": "#476291", // vintage
	"5": "#8650AC", // unusual
	"6": "#FFD700", // unique
	"7": "#70B04A", // community
	"8": "#A50F79", // valve (developer)
	"9": "#70B04A", // selfmade
	"11": "#CF6A32", // strange
	"13": "#38F3AB", // haunted
	"14": "#AA0000", // collector's
	"15": "#FAFAFA" // decorated
}


// We change the colour of the item values
// depending on their currencies
let currencyColors = {
	"keys": "yellow",
	"metal": "lightgrey",
	"usd": "green",
	"hat": "peru", // a light brown colour
	"": "grey", // Unpriced
}


let unpricedString = "This item is unpriced on BP.tf";





export default function ItemDisplay(props) {

	const { userData, setUserData } = useContext(UserDataContext);
	const linkMode = userData.linkMode;

	const itemObj = props.itemObj;
	const visibleButtons = props.buttons ? props.buttons : []; // List of buttons to show on the item display
	const disabledButtons = props.disabledButtons ? props.disabledButtons : []; // List of show buttons that are shown as disabled

	function setSavedItems(savedItems) {
		setUserData({ ...userData, savedItems: savedItems });
	}

	// Adds this item object to the list of saved items
	function saveItem() {
		let savedItems = userData.savedItems;
		if (savedItems === undefined) {
			savedItems = [];
		}
		savedItems.push(itemObj);
		setSavedItems(savedItems);
	}


	// Copilot magic
	function removeItem() {
		let savedItems = userData.savedItems;
		if (savedItems === undefined) {
			savedItems = [];
		}
		let index = savedItems.indexOf(itemObj);
		if (index > -1) {
			savedItems.splice(index, 1);
		}
		setSavedItems(savedItems);
	}


	// Data for all buttons that can be shown on the item display
	// "content" key can be either a string or an image tag
	//
	//
	// TODO: implement this properly. Why isn't this common for *all* buttons?
	// Why are there styles defined in the external CSS too? What the fuck
	const buttons = [
		{
			"name": "addSaved",
			"content": "+",
			"onClick": (() => saveItem()),
			"customStyle": {
				backgroundColor: 'green'
			}
		},
		{
			"name": "removeSaved",
			"content": "-",
			"onClick": (() => removeItem()),
			"customStyle": {
				backgroundColor: 'red'
			}
		},
	]





	return (
		<div id={itemObj.name} className="itemDisplay">
			<div className="resultImage">
				{/* These images don't really convey anything the text doesn't, so no alt text is needed*/}
				<img src={itemObj.imageUrl} style={{ borderColor: qualityColors[itemObj.qualityID] }} alt=""></img>
			</div>
			<div className="mainResultContent">

				{visibleButtons.map((buttonName, index) => {
					let buttonData = buttons.find(button => button.name === buttonName);
					return (
						<button
							className="itemDisplayButton"
							style={{
								cursor: buttonData.onClick ? "pointer" : "not-allowed",
								backgroundColor: disabledButtons.includes(buttonName) ? "lightgrey" : "inherit",
								...buttonData.customStyle
							}}
							onClick={buttonData.onClick}
							key={index}
						>
							{buttonData.content}
						</button>
					);
				})}

				<div className="resultName">
					{itemObj.name}
				</div>
				<div className="resultValue" style={{ color: currencyColors[itemObj.valueCurrency] }}>
					{itemObj.value !== '' ? (
						`${itemObj.value} ${itemObj.valueCurrency === "metal" ? "ref" : itemObj.valueCurrency}` // "ref" is more familiar than "metal"
					) : (
						<i>{unpricedString}</i>
					)}
				</div>


				<div className="itemLinks">
					<a className="bptfLink" href={itemObj.bptfLink} target={linkMode === "new tab" ? "_blank" : null} rel="noreferrer">BP.tf</a>
					<a className="scmLink" href={itemObj.scmLink} target={linkMode === "new tab" ? "_blank" : null} rel="noreferrer">SCM</a>
					<a className="mptfLink" href={itemObj.mptfLink} target={linkMode === "new tab" ? "_blank" : null} rel="noreferrer">MP.tf</a>
				</div>
			</div>
		</div >
	);
}
